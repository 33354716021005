<template>
  <v-card width="320" color="primary">
    <v-card-title class="white--text">关于我们</v-card-title>
    <v-card-subtitle class="white--text">About Us</v-card-subtitle>
    <v-list>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Our Group', icon: 'mdi-clock', to: '/about/1' },
      { text: 'Our Company', icon: 'mdi-account', to: '/about/2' },
      { text: 'Corporate Culture', icon: 'mdi-flag', to: '/about/3' },
      { text: 'Plant and Equipment', icon: 'mdi-flag', to: '/about/4' },
      { text: 'Certificate', icon: 'mdi-flag', to: '/about/5' },
      { text: 'Contact Us', icon: 'mdi-flag', to: '/about/6' },
    ],
  }),
}
</script>
