<template>
  <v-container fluid class="pa-0">
    <v-row>
      <img src="../assets/about.png" height="100%" width="100%">
    </v-row>
    <v-row>
      <v-col
        cols="3"
        class="d-none d-md-block"
      >
        <AboutMenu class="mx-4 my-4"></AboutMenu>
        <ContactUs class="mx-4 mb-4"></ContactUs>
      </v-col>
      <v-col
        cols="12"
        md="9"
        class="pl-4 pr-6"
      >
        <h3 class="blue--text text--darken-2 py-2">{{ informations[currentId - 1].title }}</h3>
        <v-divider></v-divider>
        <v-img class="ma-4" :src="informations[currentId - 1].img"></v-img>
        <p class="mx-4">{{ informations[currentId - 1].content }}</p>
        <v-divider></v-divider>
        <v-row justify="center">
          <v-btn
            color="blue-grey lighten-3"
            class="ma-2 white--text"
            @click="goBack"
          >
            Go Back
            <v-icon right dark>mdi-undo</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AboutMenu from './AboutMenu'
import ContactUs from './ContactUs'

export default {
  components: {
    AboutMenu,
    ContactUs
  },

  data: () => ({
    currentId: null,
    informations: [
      {
        title: "Our Group",
        img: require('../assets/company.jpg'),
        content: "Our Group Our Group Our Group Our Group Our Group Our Group Our Group Our Group Our Group Our Group Our Group Our Group "
      },
      {
        title: "Our Company",
        img: require('../assets/company.jpg'),
        content: "Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company Our Company "
      },
      {
        title: "Corporate Culture",
        img: require('../assets/company.jpg'),
        content: "Corporate Culture Corporate Culture Corporate Culture Corporate Culture Corporate Culture Corporate Culture Corporate Culture "
      },
      {
        title: "Plant and Equipment",
        img: require('../assets/company.jpg'),
        content: "Plant and Equipment Plant and Equipment Plant and Equipment Plant and Equipment Plant and Equipment Plant and Equipment "
      },
      {
        title: "Certificate",
        img: require('../assets/company.jpg'),
        content: "Certificate Certificate Certificate Certificate Certificate Certificate Certificate Certificate Certificate Certificate "
      },
      {
        title: "Contact Us",
        img: require('../assets/company.jpg'),
        content: "Contact Us Contact Us Contact Us Contact Us Contact Us Contact Us Contact Us Contact Us Contact Us Contact Us Contact Us "
      },
    ],
  }),

  created () {
    this.fetchData()
  },

  watch: {
    '$route': 'fetchData'
  },

  methods: {
    fetchData () {
      this.currentId = this.$route.params.id
    },
    goBack () {
      this.$router.back()
    }
  },
}
</script>
